import { LOAD_NEWS, LOAD_NEW_CATEGORY } from '../../constants/ActionTypes';
import { fetchApi } from '../../utils/api';

export async function getPosts(payload, dispatch) {
  try {
    let response = await fetchApi(`/app/post/get-list-post`, 'get', payload);
    if (response.code !== 200) {
      return {
        rows: [],
      };
    }
    // console.log("response", response);
    if (payload.page === 1) {
      dispatch(saveNews(response.data.rows));
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}
export async function getPostsRelated(payload, dispatch) {
  try {
    let response = await fetchApi(
      `/app/post/get-list-post-relate`,
      'get',
      payload,
    );
    if (response.code !== 200) {
      return {
        rows: [],
      };
    }
    // console.log("response", response);
    if (payload.page === 1) {
      dispatch(saveNews(response.data.rows));
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}
export function saveNews(payload) {
  return {
    type: LOAD_NEWS,
    payload,
  };
}

export async function getAllCategory(payload, dispatch) {
  try {
    let response = await fetchApi(
      `/app/post/get-all-post-category`,
      'get',
      payload,
    );
    console.log(response);
    if (response.code === 200) {
      dispatch(saveCategory(response.data?.rows));
    }
    return response;
  } catch (error) {
    return {
      signal: 0,
      message: 'ERROR',
      code: 400,
    };
  }
}

export function saveCategory(payload) {
  return {
    type: LOAD_NEW_CATEGORY,
    payload,
  };
}

export async function getListPostHot(payload) {
  try {
    let response = await fetchApi(`/app/post/get-news-hot`, 'get', payload);

    if (response.code !== 200) {
      return {
        data: {},
      };
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}

export async function getListPostNew(payload) {
  try {
    let response = await fetchApi(`/app/post/get-post-new`, 'get', payload);

    if (response.code !== 200) {
      return {
        data: {},
      };
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}
