import { fetchApi } from '../../utils/api';
export async function getListSolution(payload, dispatch) {
  try {
    let response = await fetchApi(
      `/app/solution/get-list-solution`,
      'get',
      payload,
    );
    if (response.code !== 200) {
      return {
        rows: [],
      };
    }
    if (payload.page === 1) {
      // dispatch(saveNews(response.data.rows));
    }
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}

export async function getListResponseMedia(payload) {
  try {
    let response = await fetchApi(
      `/app/response/get-list-response_media`,
      'get',
      payload,
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return {
      rows: [],
    };
  }
}
