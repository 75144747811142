/* eslint-disable jsx-quotes */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import useTrans from '../../hooks/useTrans';
import MetaSeo from '../MetaSeo';
import { getPosts } from '../../redux/actions/news';
import Link from 'next/link';
import { fetchApi } from '@/utils/api';
import { getListResponseMedia } from '@/redux/actions/solution';
import Image from 'next/image';
import Lottie from 'react-lottie';
import DataLottie from '../../utils/LottieData.json';
export default function Home(props) {
  const current = moment();
  const slider = useRef();
  const dispatch = useDispatch();
  const trans = useTrans();
  // const slider_feature = useRef();
  // const [list_project, setListProject] = useState([]);
  // const [list_new, setListNew] = useState([]);
  // const [list_new_post, setListNewPost] = useState([]);
  // const [total_new_post, setTotalNewPost] = useState();
  // const [list_job, setListJob] = useState([]);
  // const [list_image_office, setListImageOffice] = useState([]);
  // const [isShowModal, setIsShowModal] = useState(false);
  // const [countSlider, setCountSlider] = useState(1);
  const [data, setData] = useState(null);
  const [activeSlide, setSlide] = useState(1);
  const [activeSlide2, setSlide2] = useState(1);
  const [nav1, SetNav1] = useState(null);
  const [nav2, SetNav2] = useState(null);
  const sider1Ref = useRef(null);
  const sider2Ref = useRef(null);
  const [dataResponMedia, setDataMedia] = useState([]);
  const [lang, setLang] = useState('');
  const clickSlider = (index, refSlider) => {
    refSlider.current.slickGoTo(index);

  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      SetNav1(sider1Ref.current);
      SetNav2(sider2Ref.current);
    }
  }, []);
  const settingSingle = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    infinite: true,
    useTransform: true,
    // prevArrow: <button className='slick-prev slick-arrow' aria-label='Previous' type='button'><img src='/assets/img/index/icn_live-prev_01.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    // nextArrow: <button className='slick-next slick-arrow' aria-label='Next' type='button'><img src='/assets/img/index/icn_live-next_01.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
    customPaging: () => <button></button>,
  };
  const settingSingle1 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    dots: true,
    arrows: false,
    // prevArrow: <button className='slick-prev slick-arrow' aria-label='Previous' type='button'><img src='/assets/img/index/icn_live-prev_01.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    // nextArrow: <button className='slick-next slick-arrow' aria-label='Next' type='button'><img src='/assets/img/index/icn_live-next_01.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    speed: 400,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
    customPaging: () => <button></button>,
  };

  const settingNav = {
    slidesToShow: 4,
    dots: false,
    arrows: true,
    useTransform: true,
    // prevArrow: <button className='slick-prev slick-arrow' aria-label='Previous' type='button'><img src='/assets/img/index/icn_arrow_silder_01.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    // nextArrow: <button className='slick-next slick-arrow' aria-label='Next' type='button'><img src='/assets/img/index/icn_arrow_silder_02.svg' alt='btn'  className='slide-arrow prev-arrow' /></button>,
    responsive: [
      {
        breakpoint: 1200,
        dots: false,
        centerMode: true,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 990,
        dots: false,
        centerMode: true,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 690,
        dots: false,
        centerMode: true,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const router = useRouter();

  useEffect(() => {
    getData();
  }, [router.locale]);

  const getData = async () => {
    let resdata = await fetchApi('/app/home/get-list-home-information', 'get', {
      sign: router.locale,
    }).then(response => {
      return response.data;
    });
    let resPost = await getPosts(
      {
        limit: 3,
      },
      dispatch,
    );

    if ((resPost, resdata)) {
      setData({ ...resdata, datanews: resPost?.rows });
    }

    let res = await getListResponseMedia(dispatch);
    setDataMedia(res);
  };

  const settingSolution = {
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    dots: false,
    prevArrow: (
      <button
        className="slick-prev slick-arrow"
        aria-label="Previous"
        type="button"
      >
        <img src="/assets/img/home/icn_prev_02.svg" alt="err" />
      </button>
    ),
    nextArrow: (
      <button
        className="slick-next slick-arrow"
        aria-label="Next"
        type="button"
      >
        <img src="/assets/img/home/icn_next_02.svg" alt="err" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    afterChange: current => setSlide(current + 1),
  };

  const settingProduct = {
    infinite: true,
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    dots: true,
    prevArrow: (
      <button
        className="slick-prev slick-arrow"
        aria-label="Previous"
        type="button"
      >
        <img src="/assets/img/home/icn_prev.svg" alt="err" />
      </button>
    ),
    nextArrow: (
      <button
        className="slick-next slick-arrow"
        aria-label="Next"
        type="button"
      >
        <img src="/assets/img/home/icn_next.svg" alt="err" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          prevArrow: (
            <button
              className="slick-prev slick-arrow"
              aria-label="Previous"
              type="button"
            >
              <img src="/assets/img/home/icn_prev_02.svg" alt="err" />
            </button>
          ),
          nextArrow: (
            <button
              className="slick-next slick-arrow"
              aria-label="Next"
              type="button"
            >
              <img src="/assets/img/home/icn_next_02.svg" alt="err" />
            </button>
          ),
        },
      },
    ],
    afterChange: current => setSlide2(current + 1),
  };

  const settingTech = {
    // autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    dots: true,
    prevArrow: (
      <button
        className="slick-prev slick-arrow"
        aria-label="Previous"
        type="button"
      >
        <img src="/assets/img/home/icn_prev.svg" alt="err" />
      </button>
    ),
    nextArrow: (
      <button
        className="slick-next slick-arrow"
        aria-label="Next"
        type="button"
      >
        <img src="/assets/img/home/icn_next.svg" alt="err" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
    customPaging: i => {
      return <button>{data?.feature_tech?.rows[i].title}</button>;
    },
  };

  const getSettingOffice = stt => {
    return {
      dots: stt,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '50px',
      adaptiveHeight: true,
      prevArrow: (
        <button
          className="slick-prev slick-arrow"
          aria-label="Previous"
          type="button"
        >
          <img src="/assets/images/home/icon-next.svg" />
        </button>
      ),
      nextArrow: (
        <button
          className="slick-next slick-arrow"
          aria-label="Next"
          type="button"
        >
          <img src="/assets/images/home/icon-next.svg" />
        </button>
      ),
    };
  };

  const dataSEO = {
    title: `${trans.layout.home} | EYEFIRE`,
    description: trans.seo.home.description,
  };
  const scrollSolution = () => {
    document.querySelector('.p-home__solution').scrollIntoView('false');
  };
  const setting_slider_mb = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 8000,
  };

  const newspaperSlide = {
    infinite: true,
    dots: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    nextArrow: (
      <button
        type="button"
        className="slick-arrow slick-next pull-left"
        aria-label="Next"
      >
        <img src="/assets/img/home/icn-next.svg" alt="err" />
      </button>
    ),
    prevArrow: (
      <button
        type="button"
        className="slick-arrow slick-prev pull-left"
        aria-label="Previous"
      >
        <img src="/assets/img/home/icn-prev.svg" alt="err" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const newspaperSlideSp = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // centerPadding: '50px',
    // adaptiveHeight: true,
    nextArrow: (
      <button
        type="button"
        className="slick-arrow slick-next pull-left"
        aria-label="Next"
      >
        <img src="/assets/img/home/icn-next.svg" alt="err" />
      </button>
    ),
    prevArrow: (
      <button
        type="button"
        className="slick-arrow slick-prev pull-left"
        aria-label="Previous"
      >
        <img src="/assets/img/home/icn-prev.svg" alt="err" />
      </button>
    ),
  };

  useEffect(() => {
    const htmlLang = document.documentElement.lang;
    setLang(htmlLang);
  });

  return (
    <>
      <MetaSeo {...dataSEO} />
      <div className="p-home">
        <div className="p-home__slider">
          <div className="slide-left">
            <Slider ref={sider1Ref} asNavFor={nav2} {...settingSingle}>
              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider ">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide1.title}
                    </div>
                    <img
                      className="img-prize"
                      src={`/assets/img/home/${trans.banner.slide1.img_prize}`}
                    />
                    <div className="card-content">
                      {trans.banner.slide1.desc}
                    </div>
                    <a
                      href="https://sc.eyefire.vn/"
                      target="_blank"
                      className="link-card"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/see-more.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  <img
                    src={`/assets/img/home/${trans.banner.slide1.img_desktop}`}
                    alt="err"
                    className="home-product-01 "
                  />
                </div>
              </div>

              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide2.title}
                    </div>
                    <div className="card-content">
                      {trans.banner.slide2.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://aiot.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  <img
                    src={`/assets/img/home/${trans.banner.slide2.img_desktop}`}
                    alt="err"
                    className="home-product"
                  />
                </div>
              </div>

              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide4.title}
                    </div>
                    <img
                      className="img-prize"
                      src={`/assets/img/home/${trans.banner.slide4.img_prize}`}
                    />
                    <div className="card-content">
                      {trans.banner.slide4.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://sc.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>
                  <img
                    src={`/assets/img/home/${trans.banner.slide4.img_desktop}`}
                    alt="err"
                    className="home-product home-product-03"
                  />
                </div>
              </div>

              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide3.title}
                    </div>
                    <img
                      className="img-prize"
                      src={`/assets/img/home/${trans.banner.slide3.img_prize}`}
                    />
                    <div className="card-content">
                      {trans.banner.slide3.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://safety.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>
                  <div className="lottie_home_safety">
                    <Lottie
                      width={650}
                      height={485}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: DataLottie,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                    />
                  </div>
                  {/* <img
                    src={`/assets/img/home/${trans.banner.slide3.img_desktop}`}
                    alt="err"
                    className="home-product"
                  /> */}
                </div>
              </div>

            </Slider>
          </div>

          <div className="BannerMobile">
            <Slider {...setting_slider_mb} className="bannerMobile-slider">
              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card header_item_1">
                      {trans.banner.slide1.title}
                    </div>

                    <img
                      style={{ margin: 'auto', width: 162.29 }}
                      src="/assets/img/home/img_slider_mb.png"
                    />

                    <div className="card-content">
                      {trans.banner.slide1.desc}
                    </div>
                    <a
                      href="https://sc.eyefire.vn/"
                      target="_blank"
                      className="link-card"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  <img
                    src={`/assets/img/home/${trans.banner.slide1.img_mb}`}
                    alt="err"
                    className="home-product"
                  />
                </div>
              </div>
              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide2.title}
                    </div>
                    <div className="card-content">
                      {trans.banner.slide2.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://aiot.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  <img
                    src={`/assets/img/home/${trans.banner.slide2.img_mb}`}
                    alt="err"
                    className="home-product"
                  />
                </div>
              </div>
              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide4.title}
                    </div>
                    <img
                      className="img-prize"
                      src={`/assets/img/home/${trans.banner.slide4.img_prize}`}
                    />
                    <div className="card-content">
                      {trans.banner.slide4.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://aiot.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  <img
                    src={`/assets/img/home/${trans.banner.slide4.img_mb}`}
                    alt="err"
                    className="home-product"
                  />
                </div>
              </div>
              <div className="Slider-home">
                <div className="home-content">
                  <div className="sub_slider">
                    <div className="title-card">{trans.banner.solution}</div>
                    <div className="header-card">
                      {trans.banner.slide3.title}
                    </div>
                    <img
                      className="img-prize"
                      src={`/assets/img/home/${trans.banner.slide4.img_prize}`}
                    />
                    <div className="card-content">
                      {trans.banner.slide3.desc}
                    </div>
                    <a
                      className="link-card"
                      href="https://safety.eyefire.vn/"
                      target="_blank"
                    >
                      {trans.text_button.read_more}
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="err"
                        loading="lazy"
                      ></img>
                    </a>
                  </div>

                  {/* <img
                    src={`/assets/img/home/${trans.banner.slide3.img_mb}`}
                    alt="err"
                    className="home-product"
                  /> */}

                  <div className="lottie_home_safety_mb">
                    {/* <Lottie
                      width={310}
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: DataLottie,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                    /> */}
                    <img
                      src={`/assets/img/home/${trans.banner.slide3.img_mb}`}
                      alt="err"
                      className="home-product"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>

          <div className="slide-right">
            <Slider ref={sider2Ref} asNavFor={nav1} {...settingNav}>
              <div
                className="sub"
                onClick={() => {
                  clickSlider(0, sider1Ref);
                }}
              >
                <div className="content">
                  <h6>{trans.banner.solution}</h6>
                  <h2>{trans.banner.slide1.title}</h2>
                </div>
              </div>
              <div
                className="sub"
                onClick={() => {
                  clickSlider(1, sider1Ref);
                }}
              >
                <div className="content">
                  <h6>{trans.banner.solution}</h6>
                  <h2>{trans.banner.slide2.title}</h2>
                </div>
              </div>
              <div
                className="sub"
                onClick={() => {
                  clickSlider(2, sider1Ref);
                }}
              >
                <div className="content">
                  <h6>{trans.banner.solution}</h6>
                  <h2>{trans.banner.slide4.title}</h2>
                </div>
              </div>
              <div
                className="sub"
                onClick={() => {
                  clickSlider(3, sider1Ref);
                }}
              >
                <div className="content">
                  <h6>{trans.banner.solution}</h6>
                  <h2>{trans.banner.slide3.title}</h2>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="p-home__technology p-home__technology--pc ">
          <div className="c-title c-title--center">
            <h3>{trans.home.hotTech}</h3>
          </div>
          <Slider className="p-home__technology-slider" {...settingTech}>
            {data?.feature_tech?.rows.map(item => {
              // let imgSrc = listImg.filter(e => item.group_id === e.id);
              // console.log(imgSrc, 'imgSrc');
              return (
                <div className="p-home__technology-item">
                  <img
                    className="w-100"
                    src={item?.url_lottie_background}
                    alt={item.title}
                    loading="lazy"
                    title={item.title}
                  />
                  <div className="p-home__technology-content">
                    <img
                      src={item.image}
                      alt={item.title}
                      loading="lazy"
                      title={item.title}
                    />
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                    <div className="btn-technology">
                      <Link href="/technology">
                        <a href="#" className="c-btn">
                          {trans.text_button.more}
                        </a>
                      </Link>
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt={item.title}
                        loading="lazy"
                        title={item.title}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="p-home__technology p-home__technology--sp">
          <div className="c-title c-title--center">
            <h3>{trans.home.hotTech}</h3>
          </div>
          <div className="p-home__technology-slider">
            {data?.feature_tech?.rows.map(item => {
              return (
                <div className="p-home__technology-item">
                  <img
                    className="w-100"
                    src={item.url_lottie_background_mobile}
                    alt={item.title}
                    loading="lazy"
                  />
                  <div className="p-home__technology-content">
                    <img
                      src={item.image}
                      alt={item.title}
                      loading="lazy"
                      title={item.title}
                    />
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                    <div>
                      <Link href="/technology">
                        <a href="#" className="c-btn">
                          {trans.text_button.more}
                        </a>
                      </Link>
                      <img
                        src="/assets/img/home/icn_right.svg"
                        alt="error"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="p-home__solution">
          <div className="l-container">
            <div style={{ padding: ' 0 2px' }}>
              <div className="c-title c-title--border">
                <h3>{trans.home.solutions}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${trans.home.solutions_des}`,
                  }}
                ></p>
                <Link href="/solution">
                  <a href="#" className="c-btn-more hide-pc mt-24">
                    {trans.text_button.detail}
                  </a>
                </Link>
              </div>
              <div className="l-row">
                {data?.solution?.rows?.map(row => (
                  <div className="l-col">
                    <div className="p-home__solution-item">
                      <div>
                        <div className="p-home__solution-icon">
                          <img
                            src={row?.background_image}
                            alt="err"
                            style={{ maxWidth: '100%', borderRadius: '40%' }}
                            loading="lazy"
                            title={row.title}
                          />
                        </div>
                        <h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.title,
                            }}
                          ></div>
                          {/* {row.title} */}
                        </h3>
                        {row?.id === 10 && (
                          <img
                            src="/assets/img/home/img_solution.png"
                            className="img_prize"
                          />
                        )}
                        <p>
                          {/* {row.description} */}
                          <div
                            className="home-news"
                            dangerouslySetInnerHTML={{
                              __html: row.description_short,
                            }}
                          />
                        </p>
                      </div>
                      <div className="p-home__solution-btn btn-solution">
                        <Link href="/solution">
                          <a href="#" className="c-btn" tabIndex="0">
                            {trans.text_button.more}
                          </a>
                        </Link>
                        <img
                          src="/assets/img/home/icn_right.svg"
                          alt="err"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-home__solution-slider-count">
                <span className="current-slide">{`0${activeSlide}`}</span>
                <span className="separate"></span>
                <span className="total-slide">{`0${data?.solution?.rows.length}`}</span>
              </div>
              <Slider className="p-home__solution-slider " {...settingSolution}>
                {data?.solution?.rows.map(row => {
                  return (
                    <div className="p-home__solution-item">
                      <div>
                        <div className="p-home__solution-icon">
                          <img
                            src={row.background_image}
                            alt={row.title}
                            loading="lazy"
                            title={row.title}
                          />
                        </div>

                        <h3>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: row.title,
                            }}
                          ></div>
                          {/* {row.title} */}
                        </h3>
                        {row?.id === 10 && (
                          <img src="/assets/img/home/img_home_solution_mb.svg" />
                        )}
                        <div
                          className="home-news"
                          dangerouslySetInnerHTML={{
                            __html: row.description_short,
                          }}
                        />
                      </div>
                      <div className="p-home__solution-btn">
                        <Link href="/solution">
                          <a href="#" className="c-btn" tabIndex="0">
                            {trans.text_button.more}
                          </a>
                        </Link>
                        <img
                          src="/assets/img/home/icn_right.svg"
                          alt="err"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        <div className="p-home__products">
          <div className="l-container">
            <div className="c-title c-title--center">
              <h3>{trans.home.product}</h3>
              <p>{trans.home.product_sub}</p>
              <Link href="/product">
                <a href="#" className="c-btn-more">
                  {trans.text_button.detail}
                </a>
              </Link>
            </div>
            <div className="p-home__products-slider-count item-sp">
              <span className="current-slide">{`0${activeSlide2}`}</span>
              <span className="separate"></span>
              <span className="total-slide">{`0${data?.product?.rows.length}`}</span>
            </div>
            <Slider className="p-home__products-slider" {...settingProduct}>
              {data?.product?.rows?.map((row, index) => {
                return (
                  <div
                    className={`p-home__products-item p-home__products-item-0${index +
                      1}`}
                    key={index}
                  >
                    <div className="l-row">
                      <div className="l-col-auto p-home__products-left">
                        <div className="item-sp">
                          <h3>{row.name}</h3>
                          <p>{row.description}</p>
                        </div>
                        <img
                          className="w-100 pt320"
                          src="/assets/img/home/img_bg_produts.webp"
                          alt={row.description}
                          loading="lazy"
                          title={row.title}
                        />
                        <div
                          className={`p-home__products-left__image pt320 item0${index +
                            1}`}
                        >
                          <img
                            src={row.avatar}
                            className=""
                            alt={row.description}
                            loading="lazy"
                            title={row.title}
                          />
                        </div>
                      </div>
                      <div className="l-col p-home__products-right">
                        <div className="p-home__products-right-inner">
                          <div className="item-pc">
                            <div className="p-home__products-slider-count">
                              <span className="current-slide">{`0${activeSlide2}`}</span>
                              <span className="separate"></span>
                              <span className="total-slide">{`0${data?.product?.rows.length}`}</span>
                            </div>
                            <h3>{row.name}</h3>
                            <p>{row.description}</p>
                          </div>
                          <ul className="p-home__products-features">
                            {row?.description_sub.map(sub => (
                              <li>
                                <div className="p-home__products-features-icon">
                                  <img
                                    src={sub.image}
                                    alt={row.description}
                                    loading="lazy"
                                    title={row.title}
                                  />
                                </div>
                                <div className="p-home__products-features-info">
                                  <h5>{sub.title}</h5>
                                  <p>{sub.text}</p>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div className="p-home__facesdk">
          <div className="l-container">
            <div className="c-title c-title--center">
              <h3>EYEFIRE FACESDK</h3>
              <div
                dangerouslySetInnerHTML={{ __html: `${trans.home.facesdk}` }}
              ></div>
              <Link href="/sdk">
                <a href="#" className="c-btn-more">
                  {trans.text_button.detail}
                </a>
              </Link>
            </div>
            <div className="l-row ">
              <div className="l-col">
                <div className="p-home__facesdk-item">
                  <div className="p-home__facesdk-inner">
                    <img
                      src="/assets/img/home/a1.webp"
                      alt="err"
                      loading="lazy"
                    />
                    <h3>Mobile SDK</h3>
                  </div>
                </div>
              </div>
              <div className="l-col">
                <div className="p-home__facesdk-item">
                  <div className="p-home__facesdk-inner">
                    <img
                      src="/assets/img/home/b1.webp"
                      alt="err"
                      loading="lazy"
                    />
                    <h3>Server SDK</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-home__prize">
          <Slider {...settingSingle}>
              <div className="Slider-home">
                { lang === 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/bg-prize.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang === 'vi' &&
                    <div className="img-home_prize item-sp">
                      <Image
                        className="item-sp"
                        src="/assets/img/home/bg-prize-sp.png"
                        alt="err"
                        width={327}
                        height={691}
                      />
                    </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/img-prize-en-school-pc.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-sp">
                    <Image
                      className="item-sp"
                      src="/assets/img/home/img-prize-en-school-sp.png"
                      alt="err"
                      width={327}
                      height={691}
                    />
                  </div>
                }
              </div>
              <div className="Slider-home">
                { lang === 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/Face_recognition_vi.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang === 'vi' &&
                  <div className="img-home_prize item-sp">
                    <Image
                      className="item-sp"
                      src="/assets/img/home/Face_recognition_vi-sp.png"
                      alt="err"
                      width={327}
                      height={691}
                    />
                  </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/Face_recognition_en.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-sp">
                    <Image
                      className="item-sp"
                      src="/assets/img/home/Face_recognition_en-sp.png"
                      alt="err"
                      width={327}
                      height={691}
                    />
                  </div>
                }
              </div>
              <div className="Slider-home">
                { lang === 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/Safety-vi.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang === 'vi' &&
                  <div className="img-home_prize item-sp">
                    <Image
                      className="item-sp"
                      src="/assets/img/home/Safety_vi-sp.png"
                      alt="err"
                      width={327}
                      height={691}
                    />
                  </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-pc">
                    <Image
                      className="item-pc"
                      src="/assets/img/home/Safety-en.png"
                      alt="err"
                      width={1681}
                      height={794}
                      loading="lazy"
                    />
                  </div>
                }
                { lang !== 'vi' &&
                  <div className="img-home_prize item-sp">
                    <Image
                      className="item-sp"
                      src="/assets/img/home/Safety-en-sp.png"
                      alt="err"
                      width={327}
                      height={691}
                    />
                  </div>
                }
              </div>
          </Slider>
          {/* <div className="img-home_prize item-pc">
            <Image
              className="item-pc"
              src="/assets/img/home/bg-prize.png"
              alt="err"
              width={1681}
              height={794}
              loading="lazy"
            />
          </div>

          <div className="img-home_prize item-sp">
            <Image
              className="item-sp"
              src="/assets/img/home/img_prize_mb.jpg"
              alt="err"
              width={327}
              height={691}
            />
          </div> */}
        </div>

        <div className="p-home__newspaper">
          <div className="p-home__newspaper__coat"></div>
          <div className="p-home__newspaper__container">
            <div className="p-home__newspaper__left">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${trans.home?.prize_title}`,
                }}
              />
            </div>
            <div className="p-home__newspaper__right">
              <div className="item-pc">
                <Slider {...newspaperSlide}>
                  {dataResponMedia?.map((item, index) => {
                    return (
                      <div className="inner" key={index}>
                        <div className="box">
                          <div className="left">
                            <img
                              className="item-pc"
                              src="/assets/img/home/icn-wrap-text.svg"
                              alt="err"
                            />
                            <p>{item?.response_media}</p>
                            <a href={item?.link} target="_blank">
                              Xem thêm
                              <img
                                src="/assets/img/home/icn-seemore.svg"
                                alt="err"
                              />
                            </a>
                          </div>
                          <div className="right">
                            <img
                              src={item?.background}
                              alt="err"
                              title={item.title}
                            />
                          </div>
                          <div className="logo">
                            <img
                              src={item?.logo}
                              alt={item.title}
                              title={item.title}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
              <div className="item-mb">
                <Slider {...newspaperSlideSp}>
                  {dataResponMedia?.map((item, index) => {
                    return (
                      <div className="inner" key={index}>
                        <div className="box">
                          <div className="left">
                            <img
                              className="item-pc"
                              src="/assets/img/home/icn-wrap-text.svg"
                              alt="err"
                            />
                            <p>{item?.response_media}</p>
                            <a href={item?.link} target="_blank">
                              Xem thêm
                              <img
                                src="/assets/img/home/icn-seemore.svg"
                                alt="err"
                              />
                            </a>
                          </div>
                          <div className="right">
                            <img
                              src={item?.background}
                              alt="err"
                              title={item.title}
                            />
                          </div>
                          <div className="logo">
                            <img src={item?.logo} title={item.title} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="p-home__news">
          <div className="l-container">
            <div className="c-title c-title--border">
              <h3>{trans.home.news}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: `${trans.home.new_des}` }}
              ></p>
            </div>
            <div className="l-row">
              <div className="l-col l-col-left news-pc">
                <div className="p-home__news-item">
                  <Link href={`/news/${data?.post?.rows[0]?.seo_url}`}>
                    <a href="#">
                      <img
                        className="w-100"
                        src={data?.post?.rows[0]?.image}
                        alt={data?.post?.rows[0]?.description}
                        loading="lazy"
                        title={data?.post?.rows[0]?.title}
                      />
                    </a>
                  </Link>
                  <div className="p-home__news-info">
                    <Link href={`/news/${data?.post?.rows[0]?.seo_url}`}>
                      <h3>{data?.post?.rows[0]?.title}</h3>
                    </Link>
                    <div className="p-home__news--date">
                      <p>
                        <span className="p-home__news--date-text">
                          {' '}
                          {data?.post?.rows[0]?.postcategory?.name}
                        </span>{' '}
                        <span>
                          {moment(data?.post?.rows[0]?.show_date).format(
                            'DD/MM/YYYY',
                          )}
                        </span>
                      </p>
                    </div>
                    <p>
                      <div
                        className="p-home__news-content"
                        dangerouslySetInnerHTML={{
                          __html: data?.post?.rows[0]?.html_content,
                        }}
                      ></div>
                    </p>
                    {/* <div className="p-home__news-cate"> */}
                    {/*   {data?.post?.rows[0]?.postcategory?.name} */}
                    {/* </div> */}
                    <Link href={`/news/${data?.post?.rows[0]?.seo_url}`}>
                      <a href="#" className="c-btn">
                        {trans.text_button.detail}
                      </a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="l-col l-col-right">
                <div className="l-row item-pc">
                  {data?.datanews?.map(row => {
                    return (
                      <div className="l-col">
                        <div className="p-home__news-item">
                          <Link href={`/news/${row.seo_url}`}>
                            <a href="#">
                              <img
                                className="w-100"
                                src={row.image}
                                alt={row.description}
                                loading="lazy"
                                title={row.title}
                              />
                            </a>
                          </Link>
                          <div className="p-home__news-info">
                            <Link
                              href={`/news/${data?.post?.rows[0]?.seo_url}`}
                            >
                              <h4>{row.title}</h4>
                            </Link>
                            <div className="p-home__news--date">
                              <p>
                                <span className="p-home__news--date-text">
                                  {row?.postcategory?.name}
                                </span>{' '}
                                <span>
                                  {moment(row?.show_date).format('DD/MM/YYYY')}
                                </span>
                              </p>
                            </div>
                            {/* <div className="p-home__news-cate">
                                {row?.postcategory?.name}
                              </div>
                              <div className="p-home__news-time">
                                {moment(row?.show_date).format('DD/MM/YYYY')}
                              </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="p-home__news-slider item-sp">
                  <div className="p-home__news-item">
                    <Link href={`/news/${data?.post?.rows[0]?.seo_url}`}>
                      <a href="#">
                        <img
                          className="w-100"
                          src={data?.post?.rows[0]?.image}
                          alt={data?.post?.rows[0]?.description}
                          loading="lazy"
                        />
                      </a>
                    </Link>
                    <div className="p-home__news-info">
                      <Link href={`/news/${data?.post?.rows[0]?.seo_url}`}>
                        <h4>{data?.post?.rows[0]?.title}</h4>
                      </Link>
                      <div
                        className="p-home__news-content"
                        dangerouslySetInnerHTML={{
                          __html: data?.post?.rows[0]?.html_content,
                        }}
                      ></div>
                      <div className="p-home__news--date">
                        <p>
                          <span className="p-home__news--date-text">
                            {' '}
                            {data?.post?.rows[0]?.postcategory?.name}
                          </span>{' '}
                          <span>
                            {moment(data?.post?.rows[0]?.show_date).format(
                              'DD/MM/YYYY',
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <Slider
                  className="p-home__news-slider item-sp item-sp-2"
                  {...getSettingOffice(false)}
                >
                  {data?.post?.rows.map((row, index) => {
                    if (index !== 0) {
                      return (
                        <div className="p-home__news-item home_slide_new_mobile">
                          <Link href={`/news/${row.seo_url}`}>
                            <a href="#">
                              <img
                                className="w-100"
                                src={row.image}
                                alt={row.title}
                                loading="lazy"
                                title={row.title}
                              />
                            </a>
                          </Link>
                          <div className="p-home__news-info">
                            <Link href={`/news/${row.seo_url}`}>
                              <h4>{row.title}</h4>
                            </Link>

                            <div className="p-home__news--date">
                              <p>
                                <span className="p-home__news--date-text">
                                  {' '}
                                  {data?.post?.rows[0]?.postcategory?.name}
                                </span>{' '}
                                <span>
                                  {moment(
                                    data?.post?.rows[0]?.show_date,
                                  ).format('DD/MM/YYYY')}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <div className="p-home__fields">
          <div className="l-container">
            <div className="c-title c-title--center">
              <h3>{trans.home.field}</h3>
              <p style={{ fontWeight: 500 }}>{trans.home.field_sub}</p>
            </div>
            <div className="l-row item-pc">
              {data?.field?.rows?.map(row => {
                return (
                  <div className="l-col">
                    <div className="p-home__fields-item">
                      <div className="p-home__fields-icon">
                        <img
                          style={{ maxHeight: 59 }}
                          src={row.image}
                          alt={row.description}
                          title={row.title}
                          loading="lazy"
                        />
                      </div>
                      <h3>{row.title}</h3>
                      <ul>
                        {row.description_sub.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>

            <Slider
              className="p-home__fields-slider item-sp"
              {...getSettingOffice(true)}
            >
              {data?.field?.rows?.map(row => {
                return (
                  <div className="p-home__fields-box">
                    <div className="p-home__fields-item">
                      <div className="p-home__fields-icon">
                        <img
                          src={row.image}
                          alt={row.description}
                          title={row.title}
                          loading="lazy"
                        />
                      </div>
                      <h3>{row.title}</h3>
                      <ul>
                        {row.description_sub.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>

        <div className="p-home__partner">
          <div className="l-container">
            <div className="c-title c-title--center">
              <h3>{trans.home.customer}</h3>
              <p className="item-pc">{trans.home.customer_des}</p>
              <p className="item-sp">{trans.home.customer_des}</p>
            </div>
            <div className="p-home__partner-list">
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_01.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_02.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_03.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_04.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_05.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_06.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_07.png" alt="err" />
              </div>
              <div className="p-home__partner-item">
                <img src="/assets/img/home/img_partner_08.png" alt="err" />
              </div>
            </div>
          </div>
        </div>

        <div className="p-home__integrated">
          <div className="l-container">
            <div className="c-title c-title--center">
              <h3>{trans.home.start}</h3>
              <p className="item-pc">{trans.home.start_des}</p>
              <p className="item-sp">{trans.home.start_des}</p>
              <Link href="/contact">
                <a href="#" className="c-btn btn-home-contact">
                  {trans.home.contact}
                </a>
              </Link>
            </div>
            <div className="l-row">
              <div className="l-col">
                <div className="p-home__integrated-item">
                  <div className="p-home__integrated-icon">
                    <img
                      src="/assets/img/home/icn_integrated_01.svg"
                      alt="err"
                    />
                  </div>
                  <h3>{trans.home.integrated_title1}</h3>
                  <p
                    className="item-1"
                    dangerouslySetInnerHTML={{
                      __html: `${trans.home.integrated_content1}`,
                    }}
                  ></p>
                </div>
              </div>
              <div className="l-col">
                <div className="p-home__integrated-item">
                  <div className="p-home__integrated-icon">
                    <img
                      src="/assets/img/home/icn_integrated_02.svg"
                      alt="err"
                    />
                  </div>
                  <h3>{trans.home.integrated_title2}</h3>
                  <p
                    className="item-2"
                    dangerouslySetInnerHTML={{
                      __html: `${trans.home.integrated_content2}`,
                    }}
                  ></p>
                </div>
              </div>
              <div className="l-col">
                <div className="p-home__integrated-item">
                  <div className="p-home__integrated-icon">
                    <img
                      src="/assets/img/home/icn_integrated_03.svg"
                      alt="err"
                    />
                  </div>
                  <h3>{trans.home.integrated_title3}</h3>
                  <p
                    className="item-3"
                    dangerouslySetInnerHTML={{
                      __html: `${trans.home.integrated_content3}`,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
